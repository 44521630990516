import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

export default function VotingRecord() {
  return (
    <Layout>
      <SEO title="2021 Voting Record" />
      <h1>2021 Voting Record</h1>
      <ul className="mt-3 ml-5 list-disc list-outside">
        <li>
          <Link to="/2021/state-senate-votes">State Senate Votes</Link>
        </li>
        <li>
          <Link to="/2021/state-assembly-votes">State Assembly Votes</Link>
        </li>
      </ul>
    </Layout>
  )
}
